import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import bg from "../assets/img/bg.jpg";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projects = [
    {
      title: "Web Design",
      description: "We specialize in creating enchanting web designs that bring your digital presence to life.",
      imgUrl: bg,
    },
    {
      title: "Graphic Design & Branding",
      description: "Our team is skilled in crafting stunning visual identities that resonate with your target audience.",
      imgUrl: bg,
    },
    {
      title: "Marketing",
      description: "We develop innovative marketing strategies to enhance your brand's visibility and engagement.",
      imgUrl: bg,
    },
  ];

  return (
    
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible}>
                <h2>Services</h2>
                <p>Services we provide</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">

                  <Tab.Content id="slideInUp" className={isVisible}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                   
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
        
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
